<div
  class="lg:flex flex-column align-items-center justify-content-center bg-address pt-8"
>
  <div class="lg:col-6 mx-auto pb-6 pt-5 px-5">
    <div class="text-center mb-3">
      @if(!isEdit){
      <div class="text-title mb-3 font-light font-normal">Endereço</div>
      }@else {
      <div class="text-title mb-3 font-light font-normal">Editar endereço</div>
      }
    </div>
    <form [formGroup]="validateForm">
      <div class="col-12">
        <div class="formgrid grid">
          <div class="field col-12 lg:col-12 mb-4">
            <label class="block mb-2">CEP</label>
            <p-inputMask
              mask="99999-999"
              formControlName="zipCode"
              (onComplete)="getEnderecoCEP()"
              placeholder="00000-000"
              type="text"
              class="w-full md:w-full mb-3"
              id="cep"
              [style]="{ width: '100%' }"
            ></p-inputMask>
          </div>
          <div class="field col-12 lg:col-12">
            <label class="block mb-2">Endereço (Rua, Av. etc.)</label>
            <input
              id="address"
              type="text"
              formControlName="street"
              placeholder="Digite"
              pInputText
              class="w-full md:w-full mb-3"
            />
          </div>

          <div class="field col-12 lg:col-6">
            <label class="block mb-2">Número</label>
            <input
              id="numberAddress"
              #numeroInput
              type="number"
              formControlName="number"
              placeholder="Digite"
              pInputText
              class="w-full md:w-full mb-3"
            />
          </div>

          <div class="field col-12 lg:col-6">
            <label class="block mb-2">Complemento</label>
            <input
              id="complement"
              type="text"
              placeholder="Digite"
              formControlName="complement"
              pInputText
              class="w-full md:w-full mb-3"
            />
          </div>

          <div class="field col-12 lg:col-12">
            <label class="block mb-2">Bairro</label>
            <input
              id="address"
              type="text"
              placeholder="Digite"
              pInputText
              formControlName="neighborhood"
              class="w-full md:w-full mb-3"
            />
          </div>

          <div class="field col-12 lg:col-8">
            <label class="block mb-2">Cidade</label>
            <input
              id="city"
              type="text"
              placeholder="Digite"
              pInputText
              formControlName="city"
              class="w-full md:w-full mb-3"
            />
          </div>

          <div class="field col-12 lg:col-4 mb-4">
            <label class="block mb-2">UF</label>
            <input
              id="uf"
              type="text"
              placeholder="Digite"
              pInputText
              formControlName="state"
              class="w-full md:w-full mb-3"
            />
          </div>

          <div class="col-12 flex flex-column md:flex-row align-items-center gap-3 lg:pt-5 flex-column-reverse">
            <p-button
              label="Sair"
              severity="success"
              styleClass="p-button-after my-3-3 lg:my-0 "
              *ngIf="!isEdit"
              [routerLink]="['/login']"
            ></p-button>
            <p-button
              label="Continuar >>"
              severity="help"
              styleClass="p-button-save lg:ml-3 "
              (onClick)="submitForm()"
              [disabled]="validateForm.invalid"
              *ngIf="!isEdit"
            ></p-button>
            <p-button
              label="Voltar"
              severity="help"
              styleClass="p-button-after"
              icon="pi pi-angle-double-left"
              [routerLink]="['/minha-conta']"
              *ngIf="isEdit"
            ></p-button>
            <p-button
              label="Salvar edições"
              severity="success"
              styleClass="p-button-save w-full"
              [disabled]="validateForm.invalid"
              (onClick)="submitForm()"
              *ngIf="isEdit"
            ></p-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
