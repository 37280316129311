import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NavigationEnd, Router } from '@angular/router';
import { StyleClassModule } from 'primeng/styleclass';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PartnerService } from '../../services/partner.service';

@Component({
  selector: 'header',
  standalone: true,
  imports: [CommonModule, StyleClassModule, RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
  iconActual: string = 'pi pi-bars';
  showBars: boolean = true;

  currentRouter: string;
  currentStep: number;

  isMobile: boolean = false;
  isActive: boolean = false;
  isCupon: boolean = false;
  isMyCupons: boolean = false;
  isParceiro: boolean = false;
  isEditar: boolean = false;
  isPagamento: boolean = false;


  isRegisterStep: any = ['/endereco', '/dados-complementares', '/anamnese'];

  categoriaParceiro: any = {
    id: '',
    name: '',
  };

  isLogoOnly: any = [
    '/login',
    '/cadastro',
    '/email-redefinir-senha',
    '/redefinir-senha',
  ];
  subs: any = {};
  isSubscribed: boolean = false;
  isQuiosque: boolean = false;
  categoriasParceiros: any = [];
  isLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private partnerService: PartnerService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    if (sessionStorage !== undefined) {
      this.isLoggedIn = sessionStorage.getItem('token') !== null;
      this.isQuiosque = sessionStorage.getItem('quiosque') === 'true';
      this.router.events.subscribe((event) => {
        if (!this.isLoggedIn !== undefined) {
          this.isLoggedIn = sessionStorage.getItem('token') !== null;
        }
      });
    }
    else {
      sessionStorage = window.sessionStorage;
      this.isQuiosque = sessionStorage.getItem('quiosque') === 'true';
    }

    this.checkIfMobile();
    this.getSubscriptionFromStorage();
    this.router.events.subscribe((event) => {
      if (sessionStorage !== undefined) {
        this.isQuiosque = sessionStorage.getItem('quiosque') === 'true';
      }
      else {
        sessionStorage = window.sessionStorage;
        this.isQuiosque = sessionStorage.getItem('quiosque') === 'true';
      }
      this.getSubscriptionFromStorage();
      if (event instanceof NavigationEnd) {
        this.getSubscriptionFromStorage();
        const currentUrl = this.router.url;
        this.updateStep(currentUrl);
        this.currentRouter = event.url;
        this.verifyRouteIfChange();
      }
    });
    if (this.router.url.includes('cupons')) {
      this.isCupon = true;
    } else if (this.router.url.includes('parceiros')) {
      this.verifyIsPartnerRoute(this.router.url);
    } else if (this.router.url.includes('editar')) {
      this.isEditar = true;
    } else if (this.router.url.includes('pagamento')) {
      this.isPagamento = true;
    }

    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((res) => {
      this.isMobile = res.matches;
    });
    this.updateStep(this.router.url);
  }

  ngOnDestroy() { 
    this.router.events.subscribe().unsubscribe();
  }

  verifyRouteIfChange() {
    if (this.currentRouter.includes('parceiros')) {
      this.verifyIsPartnerRoute(this.currentRouter);
    } else if ((this.currentRouter.includes('cupons') && !this.currentRouter.includes('meus-cupons'))) {
      this.isCupon = true;
    } else if (this.currentRouter.includes('editar')) {
      this.isEditar = true;
    } else if (this.currentRouter.includes('pagamento')) {
      this.isPagamento = true;
    } else {
      this.isCupon = false;
      this.isParceiro = false;
      this.isEditar = false;
      this.isPagamento = false;
      this.isMyCupons = false;
    }
  }

  getSubscriptionFromStorage() {
    if (sessionStorage !== undefined) {
      const subscriptions: any = sessionStorage.getItem('subscriptions');
      if (subscriptions !== null && subscriptions !== undefined && subscriptions !== 'undefined') {
        this.subs = JSON.parse(subscriptions ?? '{}');
        if (this.subs && Object.keys(this.subs).length > 0) {
          this.isSubscribed = true;
        }
      }
    }
  }

  async verifyIsPartnerRoute(currentRouter: any) {
    this.isParceiro = true;
    this.categoriasParceiros =
      await this.partnerService.getCategoriasParceiros();
    if (this.categoriasParceiros) {
      const urlSegments = currentRouter.split('/');
      if (urlSegments.length == 2) {
        this.categoriaParceiro.name = 'Parceiros';
        return;
      }
      const parceiro = this.categoriasParceiros.find(
        (cat: any) => cat.id === urlSegments[urlSegments.length - 1]
      );
      if (parceiro) {
        this.categoriaParceiro.name = parceiro.name;
      }
    }
  }

  changeIcon() {
    this.isActive = !this.isActive;
    this.iconActual = this.isActive ? 'pi pi-times' : 'pi pi-bars';
  }

  verifyRoute(routeList: any) {
    const l = routeList.some((route: string) =>
      this.router.url.startsWith(route)
    );
    return l;
  }

  getTitle(): string {
    if (this.isParceiro) {
      return this.categoriaParceiro.name;
    } else if (this.isCupon) {
      return 'Cupom';
    } else if (this.isMyCupons) {
      return 'Meus Cupons';
    } else if (this.isPagamento) {
      return 'Pagamento';
    } else {
      const titleMap = {
        '/area-do-cliente': 'Área do Cliente',
        '/cupons': 'Cupons',
        '/voucher-consulta': 'Consulta',
        '/resultados': 'Resultados',
        '/medico': 'Médicos',
        '/parceiros': 'Parceiros',
        '/laboratorios': 'Laboratórios',
        '/farmacia': 'Farmácias',
        '/oticas': 'Óticas',
        '/pagamento': 'Pagamento',
        '/meus-cupons': 'Meus Cupons',
        '/assinatura': 'Assinatura',
        '/minha-conta': 'Minha Conta',
        '/editar/cadastro': 'Editar Cadastro',
        '/editar/endereco': 'Editar Endereço',
        '/editar/anamnese': 'Editar Anamnese',
        '/editar/dados-complementares': 'Editar dados',
        '/sobre-nos': 'Sobre Nós',
        '/carrinho': 'Carrinho',
      };
      const currentRoute = this.router.url;
      return titleMap[currentRoute] || '';
    }
  }


  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 998;
  }

  isEdit() {
    return this.router.url.includes('editar');
  }

  logout() {
    this.isActive = !this.isActive;
    this.iconActual = this.isActive ? 'pi pi-times' : 'pi pi-bars';
    sessionStorage.clear();
    this.router.navigate([!this.isQuiosque ? '/login' : '/login-quiosque']);
  }

  routeHaveId() { }

  closeMenu() {
    // defina a variável showBars como false para fechar o menu
    this.showBars = false;
    // defina o ícone como 'pi pi-bars' quando o menu estiver fechado
    this.iconActual = 'pi pi-bars';
  }

  updateStep(currentUrl: string): void {
    const segments = currentUrl.split('/');
    const currentRoute = segments[segments.length - 1];

    switch (currentRoute) {
      case 'endereco':
        this.currentStep = 1;
        break;
      case 'dados-complementares':
        this.currentStep = 2;
        break;
      case 'anamnese':
        this.currentStep = 3;
        break;
      default:
        this.currentStep = 1;
        break;
    }
  }
}
