// export const environment = {
//   apiUrl: 'https://estacaodoolhoapi.azurewebsites.net',
// };

// const homol_config: any = {
//   baseUrl: 'https://estacaodoolhoapihom.azurewebsites.net',
// };

export const environment = {
   apiUrl: "https://estacaodoolhoapihom-f0htbpdpf9h2aub8.eastus2-01.azurewebsites.net",
  //  apiUrl: "https://estacaodoolhoapiproducao-gpdtdmcha3gxfuec.eastus2-01.azurewebsites.net"
};
