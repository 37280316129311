import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { LoadingService } from '../../services/loading.service';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { CustomerService } from '../../services/customer.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomerPaginator } from '../../model/customerPaginator';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';


@Component({
  selector: 'app-upload-exames',
  standalone: true,
  imports: [
    ButtonModule,
    PaginatorModule,
    CarouselModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    RouterLink
  ],
  templateUrl: './upload-exames.component.html',
  styleUrls: ['./upload-exames.component.scss']
})
export class UploadExamesComponent implements OnInit {
  vouchers: any[] = [];
  searchForm: FormGroup;
  searchResults: any[] = [];
  customerBackup: any[] = [];
  customers: CustomerPaginator = { items: [], totalItems: 0, pageNumber: 1, pageSize: 9 };
  first: number = 1;
  rows: number = 9;
  totalRecords: number = 0;


  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.loadCustomers(this.first, this.rows);

    this.searchForm = this.fb.group({
      searchTerm: ['', Validators.required]
    });
  }

  limpar(){
    this.searchForm.reset();
    this.customers = this.customerBackup as any;
  }

  async loadCustomers(pageNumber: number, pageSize: number) {
    this.loadingService.show();
    try {
      const customers = await this.customerService.getPaginatedCustomer(pageNumber, pageSize, '');
      this.customers = customers;
      this.customerBackup = {...customers};
      this.totalRecords = customers.totalItems;
    } finally {
      this.loadingService.hide();
    }
  }


  async search() {
    if (this.searchForm.invalid) {
      this.customers = this.customerBackup as any;
    } else {
      const searchTerm = this.searchForm.get('searchTerm')!.value;
      console.log(searchTerm);
      try {
        this.loadingService.show();
        const response = (await this.customerService.getPaginatedCustomer(this.first, this.rows, searchTerm));
        this.loadingService.hide();

        this.customers = response;
      } catch (error) {
        this.loadingService.notify('Aviso', 'Erro ao buscar cliente: ' + error);
      }
    }
  }

  onPageChange(event: any) {
    this.rows = event.rows;
    const page = event.page+1;
    this.loadCustomers(page, this.rows); // Recarregar os dados com base na nova página
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }
}
