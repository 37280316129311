<div class="flex justify-content-center bg-renda pt-8 px-3">
  <div class="col-12 lg:col-6 mx-auto py-6 px-12">
    <div class="text-center mb-5">
      @if(!isEdit){
      <div class="text-title mb-3 font-light font-normal">
        Dados complementares
      </div>
      }@else {
      <div class="text-title mb-3 font-light font-normal">
        Editar dados complementares
      </div>
      }
    </div>
    <form [formGroup]="validateForm">
      <div class="col-12">
        <div class="formgrid grid">
          <div class="field col-12 lg:col-6">
            <label class="block mb-2">Sexo biológico</label>
            <p-dropdown
              [options]="sexos"
              formControlName="sex"
              optionLabel="description"
              optionValue="id"
              placeholder="Selecione"
              autoWidth="false"
              [style]="{ width: '100%' }"
            ></p-dropdown>
            <div
              *ngIf="
                validateForm.get('sex')?.invalid &&
                validateForm.get('sex')?.touched
              "
              class="alert alert-danger"
            >
              Campo obrigatório.
            </div>
          </div>
          <div class="field col-12 lg:col-6">
            <label class="block mb-2">Raça</label>
            <p-dropdown
              [options]="etnias"
              formControlName="ethnicity"
              optionLabel="description"
              optionValue="id"
              placeholder="Selecione"
              autoWidth="false"
              [style]="{ width: '100%' }"
            ></p-dropdown>
            <div
              *ngIf="
                validateForm.get('ethnicity')?.invalid &&
                validateForm.get('ethnicity')?.touched
              "
              class="alert alert-danger"
            >
              Campo obrigatório.
            </div>
          </div>

          <div class="field col-12 lg:col-12">
            <label class="block mb-2">Renda Familiar mensal</label>
            <p-dropdown
              [options]="salary"
              formControlName="householdIncome"
              optionLabel="salario"
              optionValue="value"
              [showClear]="true"
              placeholder="Selecione"
            ></p-dropdown>
            <div
              *ngIf="
                validateForm.get('householdIncome')?.invalid &&
                validateForm.get('householdIncome')?.touched
              "
              class="alert alert-danger"
            >
              Campo obrigatório.
            </div>
          </div>

          <div class="field col-12 lg:col-12">
            <label class="block mb-2">Possui plano de saúde?</label>
            <div class="flex flex-wrap gap-3">
              <div class="flex align-items-center">
                @for (category of radioForm; track $index) {
                <div class="field-checkbox">
                  <p-radioButton
                    [inputId]="category.value"
                    [value]="category.value"
                    formControlName="hasHealthInsurance"
                    (ngModelChange)="onChangeRadio($event)"
                  ></p-radioButton>
                  <label [for]="category.value" class="ml-2">{{
                    category.name
                  }}</label>
                </div>
                }
              </div>
            </div>
          </div>

          @if (validateForm.value.hasHealthInsurance) {
          <div class="field col-12">
            <label class="block mb-2">Qual?</label>
            <p-dropdown
              [options]="plan"
              formControlName="healthInsuranceId"
              optionLabel="name"
              optionValue="id"
              [showClear]="true"
              placeholder="Selecione"
            ></p-dropdown>
            <div
            *ngIf="
              validateForm.get('healthInsuranceId')?.invalid &&
              validateForm.get('healthInsuranceId')?.touched
            "
            class="alert alert-danger"
          >
            Selecione um plano
          </div>
          </div>
          }

          <div class="col-12 flex flex-column md:flex-row align-items-center gap-3 mt-4 flex-column-reverse">
            <p-button
              label="<< Voltar"
              severity="success"
              styleClass="p-button-after w-full"
              [routerLink]="['/endereco']"
              *ngIf="!isEdit"
            ></p-button>

            <p-button
              label="Salvar"
              [disabled]="validateForm.invalid"
              severity="help"
              styleClass="p-button-save"
              (click)="showDialog()"
              *ngIf="!isEdit"
            ></p-button>
            <p-button
              label="<< Voltar"
              severity="success"
              styleClass="p-button-after w-full"
              [routerLink]="['/minha-conta']"
              *ngIf="isEdit"
            ></p-button>
            <p-button
              label="Salvar edições"
              severity="success"
              styleClass="p-button-save"
              (onClick)="submitForm()"
              *ngIf="isEdit"
            ></p-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- modal -->
<p-dialog
  header="Cadastro concluído com sucesso!"
  styleClass="dialog-feedback"
  [(visible)]="visible"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
>
  <p class="m-0 lg:mt-0 mt-4">
    Que bom que concluiu seu cadastro! <br />
    Agora vamos fazer algumas perguntas para <br />
    saber melhor da sua saúde
  </p>

  <div class="flex flex-column pt-5">
    <div class="col-12">
      <p-button
        label="Prosseguir"
        severity="help"
        styleClass="p-button-save"
        (onClick)="submitForm()"
      ></p-button>
    </div>
  </div>
</p-dialog>
