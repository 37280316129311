import { CustomerService } from './../../services/customer.service';
import { AuthService } from './../../services/auth.service';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { Router, RouterModule } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { ValidatorComponent } from '../validator/validator.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordModule,
    RouterModule,
    CalendarModule,
    InputMaskModule,
    ScrollPanelModule
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {
  password!: string;
  validateForm: FormGroup;
  isEdit: boolean;
  user: any = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private customerService: CustomerService
  ) {
    this.createValidateForm();
  }

  async ngOnInit() {
    if (typeof window !== 'undefined') {
      const url = window.location.href;
      if (url.includes('editar')) {
        this.verifyIsEdit();
      } else {
        this.isEdit = false;
      }
    }
  }

  async verifyIsEdit() {
    this.isEdit = true;
    this.validateForm.removeControl('password');
    this.validateForm.removeControl('confirmarSenha');
    this.validateForm.removeControl('termsAndConditions');
    this.validateForm.get('cpf')?.disable();
    this.validateForm.get('email')?.disable();
    this.user = await this.getLoggedUser();
    this.validateForm.patchValue(this.user);
    if (this.user.birthDate) {
      this.validateForm.patchValue({
        birthDate: this.formatDate(this.user.birthDate),
      });
    }
  }

  createValidateForm() {
    this.validateForm = this.fb.group({
      name: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      phoneNumber: ['', [Validators.required]],
      cpf: ['', this.isEdit ? [] : [Validators.required, ValidatorComponent.cpf]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{6,}$'
          ),
        ],
      ],
      confirmarSenha: ['', [Validators.required]],
      birthDate: ['', [ValidatorComponent.ptDate]],
      termsAndConditions: [false, [Validators.requiredTrue]],
    });
  }

  toLowerCase(event: any) {
    const value = event.target.value.toLowerCase();
    this.validateForm.get('email')?.setValue(value, { emitEvent: false });
  }

  verificaSenhasIguais(): boolean {
    return (
      this.validateForm.get('password')?.value ===
      this.validateForm.get('confirmarSenha')?.value
    );
  }

  async createUser() {
    if (!this.isEdit) {
      this.validateForm.get('email')?.enable();
    }
  }

  async loginAndSetToken(resp: any) {
    sessionStorage.setItem('user', JSON.stringify(resp));
    this.authService
      .login(
        this.validateForm.get('email')?.value,
        this.validateForm.get('password')?.value
      )
      .then((resp) => {
        if (resp) {
          sessionStorage.setItem('token', JSON.stringify(resp));
          sessionStorage.removeItem('subscriptions');
          this.loadingService.notify('Cadastro realizado com sucesso');
          this.router.navigate(['/endereco']);
          this.loadingService.hide();
        }
      })
      .catch((error) => {
        this.loadingService.notify('Erro ao logar', 'error');
        this.loadingService.hide();
      });
  }

  unformatCpf(cpf:string){
    return cpf.replace(/[^\d]+/g, '');
  }

  async submitForm() {
    if(this.validateForm.invalid){
      const invalidFields = Object.keys(this.validateForm.controls).filter(key => this.validateForm.controls[key].invalid);
      this.loadingService.notify('Erro ao submeter', `Campos inválidos: ${invalidFields.join(', ')}`);
      return;
    }
    this.loadingService.show();
    if (!this.isEdit) {
      if (!this.verificaSenhasIguais()) {
        return;
      } else {
        this.validateForm.get('password')?.setErrors(null);
        this.validateForm.get('confirmarSenha')?.setErrors(null);
      }
      if (this.validateForm.valid) {
        try {
          const birthDate = this.unformatDate( this.validateForm.get('birthDate')?.value);
          const cpf = this.unformatCpf(this.validateForm.get('cpf')?.value);
          const body = {
            ...this.validateForm.value,
            birthDate: birthDate,
            cpf: cpf,
          };
          const response = await this.authService.createUser(
            body
          );
          if (response) {
            this.loginAndSetToken(response);
          } else {
            this.loadingService.notify(
              'Erro ao cadastrar',
              'Erro interno',
              '/login'
            );
            this.loadingService.hide();
            return;
          }
        } catch (error: any) {
          this.loadingService.notify('Erro ao cadastrar', error?.error?.message);
          this.loadingService.hide();
          return;
        }
      } else {
        Object.values(this.validateForm.controls).forEach((control) => {
          if (control.invalid) {
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
        });
      }
    } else {
      if (this.validateForm.valid) {
        try {
          const birthDate = this.unformatDate( this.validateForm.get('birthDate')?.value);
          const body = {
            ...this.validateForm.value,
            birthDate: birthDate,
          };
          delete body.termsAndConditions;
          this.validateForm.get('email')?.enable();
          const resp = await this.putRegisterUser(body);
          if (resp) {
            this.router.navigate(['/minha-conta']);
            this.loadingService.hide();
          } else {
            this.loadingService.notify('Erro ao atualizar', 'Erro interno');
            this.loadingService.hide();
          }
        } catch (error: any) {
          console.log('🚀 ~ RegisterComponent ~ submitForm ~ error:', error);
          this.loadingService.notify(
            'Erro ao atualizar',
            error?.error[0]?.description
          );
          this.loadingService.hide();
          return;
        }
      }
    }
  }

  unformatDate(date: string) {
    const dateArray = date.split('/');
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}T00:00:00`;
  }

  formatDate(date: string) {
    const dateArray = date.split('-');
    const day = dateArray[2].substring(0, 2);
    const month = dateArray[1];
    const year = dateArray[0];
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  verifyBackEdit() {
    if (this.isEdit) {
      this.router.navigate(['/minha-conta']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  async getLoggedUser() {
    try {
      return await this.customerService.getCustomerId();
    } catch (error) {
      console.log(error);
    }
  }

  async putRegisterUser(body: any) {
    try {
      return await this.authService.putUserInfo(
        body,
        this.user?.id || null
      );
    } catch (error) {
      console.log(error);
    }
  }
}
